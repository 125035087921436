<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <div class="txt-head mb-5">Order details</div>
      <div class="layout-border pa-5 mb-7">
        <v-layout justify-space-between>
          <v-flex>
            <div class="mb-3">ORDER: {{ order.reference_number }}</div>
            <div class="grey--text">Placed on {{ order.created_at | moment('DD MMMM YYYY HH:mm:ss') }}</div>
          </v-flex>
          <v-flex shrink text-end v-if="order.checkout_status !== 'CONFIRM' && order.checkout_status !== 'CANCEL'">
            <div
                class="blue--text mb-2 text-decoration-underline txt-m-head pointer"
                @click="onPayRequest"
            >Pay now</div>
            <div
                class="red--text text-decoration-underline txt-detail pointer"
                @click="onCancelOrder"
            >Cancel order</div>
          </v-flex>
        </v-layout>
      </div>
      <div class="layout-border mb-7">
        <div style="border-bottom: solid 1px #c4c4c4" class="pa-5">
          Status: {{ order.shipping_status ? order.shipping_status : order.checkout_status }}
        </div>
        <div class="pa-5">
<!--          <div class="bg-grey pa-5 mb-5">-->
<!--            Delivered on 30 October 2020 | Fedex Delivery-->
<!--          </div>-->
          <div>
            <v-layout class="pa-3" justify-space-between v-for="(x, i) in JSON.parse(order.cart.cart_items)" :key="i">
              <v-flex shrink class="d-inline-flex">
                <div>
                  <v-img
                    contain
                    class="mr-4"
                    width="100px"
                    max-height="120px"
                    :src="x.product.photos && x.product.photos[0] && x.product.photos[0].image_url ? x.product.photos[0].image_url : require('../assets/img/no-image.png')"
                  ></v-img>
                </div>
                <div>
                  <div class="txt-detail">{{ x.product.name }}</div>
                  <div class="txt-detail">size: {{ x.size }}</div>
                </div>
              </v-flex>
              <v-flex shrink>
                <div class="txt-detail">SGD {{ formatNumber(x.price_per_piece, 2) }}</div>
              </v-flex>
              <v-flex shrink>
                <div class="txt-detail">Qty: {{ x.quantity }}</div>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
      <v-layout
        justify-space-between
        mb-3
        :column="$vuetify.breakpoint.smAndDown"
      >
        <v-flex xs6 mb-3 mb-md-0>
          <div
              v-if="order.shipping_user_address"
              class="layout-border pa-5"
              :class="{ 'info-box': $vuetify.breakpoint.mdAndUp }"
          >
            <div class="txt-bigger mb-5">Shipping Address</div>
            <div class="mb-2 txt-detail">{{ order.shipping_user_address.delivery.fullName }}</div>
            <div class="mb-2 txt-detail">{{ order.shipping_user_address.delivery.addressName }}</div>
            <div class="mb-2 txt-detail">{{ order.shipping_user_address.delivery.zipCode }} {{ order.shipping_user_address.delivery.cityName }}</div>
            <div class="txt-detail">{{ order.shipping_user_address.delivery.countryName }}</div>
          </div>
        </v-flex>
        <v-flex
          xs6
          :class="{ 'd-flex justify-end': $vuetify.breakpoint.mdAndUp }"
        >
          <div
            class="layout-border pa-5"
            :class="{ 'info-box': $vuetify.breakpoint.mdAndUp }"
          >
            <div class="txt-bigger mb-5">Total Summary</div>
            <v-layout justify-space-between mb-2>
              <div class="txt-detail">SubTotal</div>
              <div class="txt-detail">{{ formatNumber(order.cart.total_price, 2) }}</div>
            </v-layout>
            <v-layout justify-space-between mb-2>
              <div class="txt-detail">Shipping Fee</div>
              <div class="txt-detail">{{ formatNumber(shippingFee, 2) }}</div>
            </v-layout>
            <Divider long="100%" border-width-additional="0.5px"></Divider>
            <v-layout justify-space-between mb-2>
              <div class="txt-detail">Total</div>
              <div class="txt-detail">{{ formatNumber(order.total_price, 2) }}</div>
            </v-layout>
            <v-layout justify-space-between>
              <div class="txt-detail">Paid by {{ order.pay_by }}</div>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
      <v-layout justify-space-between :column="$vuetify.breakpoint.smAndDown">
        <v-flex xs6>
          <div
              v-if="order.shipping_user_address"
              class="layout-border pa-5 mb-5"
              :class="{ 'info-box': $vuetify.breakpoint.mdAndUp }"
          >
            <div class="txt-bigger mb-5">Billing Address</div>
            <div class="mb-2 txt-detail">{{ order.shipping_user_address.billing.fullName }}</div>
            <div class="mb-2 txt-detail">{{ order.shipping_user_address.billing.addressName }}</div>
            <div class="mb-2 txt-detail">{{ order.shipping_user_address.billing.zipCode }} {{ order.shipping_user_address.billing.cityName }}</div>
            <div class="txt-detail">{{ order.shipping_user_address.billing.countryName }}</div>
          </div>
        </v-flex>
        <v-flex xs6>
          <div
              v-if="order.description"
              class="layout-border pa-5 mb-5"
              :class="{ 'info-box': $vuetify.breakpoint.mdAndUp }"
          >
            <div class="txt-bigger mb-5">Comment</div>
            <div class="mb-2 txt-detail">{{ order.description }}</div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Divider from '../components/Divider'
import Loading from '../components/Loading'
import Service from '../services'
import moment from "moment"
import { formatNumber } from "@/plugins/global-funcs";
import { mapState } from 'vuex'

export default {
  name: 'OrderDetail',
  components: {
    Divider,
    Loading
  },
  data () {
    return {
      isLoading: false,
      moment: moment,
      shippingFee: undefined,
      formatNumber: formatNumber
    }
  },
  computed: {
    ...mapState({
      accessToken: state => state.Auth.userDetail.access_token,
      order: state => {
        return {
          ...state.Order.order,
          shipping_user_address: JSON.parse(state.Order.order.shipping_user_address),
          cart: JSON.parse(state.Order.order.cart)
        }
      }
    })
  },
  async created() {
    this.isLoading = true
    let data = {
      access_token: this.accessToken,
      order_id: this.$route.params.id
    }
    await this.$store.dispatch('Order/onGetOrderById', data)
    if (this.order.shipping_user_address && this.order.shipping_user_address.delivery) {
      let res = await Service.address.getShippingCost({
        cart_id: this.order.cart_id,
        address_id: this.order.shipping_user_address.delivery.id
      })
      if (res.status === 200) {
        this.shippingFee = res.data.shipping_price
      }
    }
    this.isLoading = false
    // console.log(this.order)
    console.log(JSON.parse(this.order.cart.cart_items))
    console.log(this.order.cart)
    console.log(this.order)

  },
  methods: {
    async onCancelOrder () {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: 'Are you sure to cancel this order ?'
      })
      if (answer.isConfirmed) {
        this.isLoading = true
        let res = await Service.order.cancelOrder({
          access_token: this.accessToken,
          order_id: this.order.id
        })
        this.isLoading = false
        if (res.status === 200) {
          switch (res.data.code) {
            case 0:
              this.$swal.fire({
                ...this.$swalSuccess,
                text: res.data.msg
              })
              this.$router.push({ name: 'MyOrders' })
              break

            default:
              this.$swal.fire({
                ...this.$swalError,
                text: res.data.msg
              })
          }
        }
      }
    },
    async onPayRequest () {
      if (this.order.shipping_user_address && this.order.shipping_user_address.delivery) {
        let data = {
          access_token: this.accessToken,
          cart_id: this.order.cart_id,
          address_id: this.order.shipping_user_address.delivery.id
        }
        this.isLoading = true
        let res = await this.$store.dispatch('Payment/onHandlePayment', data)
        if (res.status === 200) {
          window.open(res.data.url)
        }
        this.isLoading = false
        this.$router.push({ name: 'MyOrders' })
      }
    }
  }
}
</script>

<style scoped>
.layout-border {
  border: solid 1px #c4c4c4;
}
.bg-grey {
  background: #f2f2f2;
}
.info-box {
  width: 99%;
  height: 100%;
}
</style>
